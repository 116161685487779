const getNewsArticleImageDefault = ({ newsArticleImageData, relativeDirectory }) => {
  const newsArticleImageDefault = newsArticleImageData && relativeDirectory && newsArticleImageData.filter((groupObj) => groupObj.fieldValue === relativeDirectory);
  // if we have an image called "thumbnail" we will use this for the news thumbnail image.
  const thumbnailCheck =
    newsArticleImageDefault &&
    newsArticleImageDefault[0] &&
    newsArticleImageDefault[0].edges &&
    newsArticleImageDefault[0].edges.filter((imageObj) => imageObj.node && imageObj.node.name === 'thumbnail');
  const thumbnailImage = thumbnailCheck && thumbnailCheck[0] && thumbnailCheck[0] && thumbnailCheck[0].node;
  return (
    thumbnailImage || (newsArticleImageDefault && newsArticleImageDefault[0] && newsArticleImageDefault[0].edges && newsArticleImageDefault[0].edges[0] && newsArticleImageDefault[0].edges[0].node)
  );
};

export default getNewsArticleImageDefault;
